import React from 'react';
import './styles.css';
import logoImg from '../../assets/logo.png';

export default function Home() {
  return (
    <div className='container'>
      <div className='background'></div>
      <main className='content'>
        <img className='logo' src={logoImg} alt='3D Plus logo'></img>
        <h1 className='title'>NEW 3D PLUS PROJECT MANAGEMENT Ltd. WEBSITE</h1>
        <p className='message'>Under Virtual Construction</p>
        <h2 className='sub-title'>COMING SOON.</h2>
        <p className='description'>
          3D Plus Project Management Ltd. has been providing BIM solutions to
          our client on complex projects for almost 10 years, in that time
          working with private clients to multinational blue-chip customer
          projects. Our management team has over 30 years design, planning,
          construction, co-ordination, survey, mapping and document experience
          across a vast range of project types in the AEC industry and event
          management.
        </p>
        <footer>
          Want to get in touch? We'd love to hear from you. Send an email now
          to: <strong>admin.desk@3dplus.ie</strong>
        </footer>
      </main>
    </div>
  );
}
